import * as React from "react"
import { ThemeProvider } from "@mui/material"
import { CssBaseline } from "@mui/material"

import TopBar from "../components/TopBar"
import Footer from "../components/Footer"
import lightTheme from "../themes/light"

import SmallHero from "../components/SmallHero"

import { TextField, Button, Paper} from "@mui/material"
import { FormGroup } from "@mui/material"
import { Container, Box } from "@mui/material"

import { Typography } from "@mui/material"

import { Grid } from "@mui/material"
import SendIcon from '@mui/icons-material/Send'

import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic'

//import MessengerCustomerChat from "../components/FacebookMessenger"
import { MessengerChat } from 'react-messenger-chat-plugin';


import { styled } from '@mui/material/styles';

const PREFIX = 'ContactPage';
const classes = {
  root: `${PREFIX}-root`,
  contactLink: `${PREFIX}-contactLink`,
}

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
  },
  [`& .${classes.contactLink}`]: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    borderBottom: "1px dotted",
  
    "&:hover": {
      color: theme.palette.secondary.main
    },  },
}))

const ContactPage = () => {

  return (
    <ThemeProvider theme={lightTheme}>
      <title>ITS - Contattaci</title>
      <CssBaseline />
      <TopBar  />
      <SmallHero 
        title="Contattaci"
        description="Contattaci per maggiori informazioni sui nostri servizi e prodotti.<br/><sub><sup>**Disclaimer**: in questa pagina si utilizzano cookie per migliorare l'esperienza d'uso.</sup></sub>"
        image="https://www.us-consulting.it/demo/img/contatti3.jpg"
        hideButton={true}
        />
        
        <Root className={classes.root}>
        <Container maxWidth='md' paddingTop={2} >
          <Grid container alignItems="stretch" spacing={{sm: 0, xs: 0, md: 3}}>
            <Grid item md={7} sm={12} xs={12}>
              <Paper elevation={2}>
                <Box p={3} marginBottom={{sm: 0, xs: 0, md: 3}} marginTop={3} textAlign="left">
                <Typography style={{ fontWeight: "600" , marginBottom: "0.7em"}} variant="h5">Recapiti</Typography>
                  <Grid container spacing={2} >
                    <Grid item container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={1} md={1} > <LocationOnIcon /> </Grid>
                      <Grid item  lexBasis={'fit-content'}> 
                        <strong>Sede legale</strong> 
                        <Typography variant="body1">Via delle buche, 2 – 58019 Monte Argentario (GR)</Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={1} md={1}> <AccessTimeIcon /> </Grid>
                      <Grid item flexBasis={'fit-content'}> 
                        <strong>Orario ufficio</strong> 
                        <Typography variant="body1">Aperti Lun/Ven dalle 10.30 alle 19.30</Typography> 
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={1} md={1}> <HeadsetMicIcon /> </Grid>
                      <Grid item flexBasis={'fit-content'}> 
                        <strong>Assistenza tecnica</strong> 
                        <Typography variant="body1"><a className={classes.contactLink} href="mailto:assistenza@its-tlc.it">assistenza@its-tlc.it</a></Typography> 
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={1} md={1}> <PhoneIcon /> </Grid>
                      <Grid item flexBasis={'fit-content'}> 
                        <strong>Telefono</strong> 
                        <Typography variant="body1">+39 0564 643054</Typography> 
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={1} md={1}> <EmailIcon /> </Grid>
                      <Grid item flexBasis={'fit-content'}> 
                        <strong>Email</strong> 
                        <Typography variant="body1"><a className={classes.contactLink} href="mailto:info@its-tlc.it">info@its-tlc.it</a>, pec: <a className={classes.contactLink} href="mailto:its-tlc@pec.it">its-tlc@pec.it</a></Typography> 
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item sm={12} md={5} xs={12}>
              <Paper elevation={2}>
                <Box p={3} marginY={3}>
                  <Typography style={{ fontWeight: "600" , marginBottom: "0.55em"}} variant="h5">Inviaci un messaggio</Typography>
                  <MessengerChat
                    pageId="104270401584636"
                    appId="1493453171054106"
                    language="it_IT"
                    autoExpand={false}
                  />
                  <form>
                    <Box marginBottom={0.5}> <TextField  variant="outlined" label="Nome" fullWidth autocomplete="none" required disabled/> </Box>
                    <Box marginY={0.5}> <TextField  variant="outlined" label="Email" type="email" fullWidth autocomplete="none" required disabled />  </Box>
                    <Box marginY={0.5}> <TextField  variant="outlined" label="Messaggio" fullWidth multiline rows={5} autocomplete="none" required disabled />  </Box>
                    <Box> <Button fullWidth variant="contained" color="primary" type="submit" startIcon={<SendIcon/>} disabled>Invia</Button> </Box>
                  </form>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        </Root>

      <Footer />
    </ThemeProvider>
  )
}

export default ContactPage
